import Base from '@studiometa/js-toolkit';
import Lazyload from './components/Lazyload';
import Share from './components/Share';
import Slider from './components/Slider';
import YoutubePlayer from './components/YoutubePlayer';

/**
 * App class.
 */
class App extends Base {
  /**
   * Class config.
   */
  get config() {
    return {
      name: 'App',
      components: {
        '[data-src]': Lazyload,
        Share,
        Slider,
        YoutubePlayer,
      },
    };
  }

  /**
   * Mounted hook.
   */
  mounted() {
    this.hasScrolled = false;
    this.testScroll(window.pageYOffset);
  }

  /**
   * Service scrolled hook
   * @see https://js-toolkit.meta.fr/services/scroll.html
   *
   * @param {Number} x The current horizontal scroll position.
   * @param {Number} y The current vertical scroll position.
   * @param {Object} changed For both axis, whether or not the current position has changed since the last one.
   * @param {Object} last The last position on both the x and y axis.
   * @param {Object} delta The difference between the last position and the current one.
   * @param {Object} progress The scroll position on both axis mapped to a 0 to 1 range, based on the max property.
   * @param {Object} max The maximum value the scroll can reach.
   */
  scrolled({ y }) {
    this.testScroll(y);
  }

  /**
   * Test the scroll.
   * @param {Number} y The scroll y position.
   */
  testScroll(y) {
    if (y > 100 && !this.hasScrolled) {
      this.hasScrolled = true;
      this.$el.classList.add('scrolled');
    }

    if (y < 100 && this.hasScrolled) {
      this.hasScrolled = false;
      this.$el.classList.remove('scrolled');
    }
  }
}

export default new App(document.body);


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
