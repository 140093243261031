import Base from '@studiometa/js-toolkit';
import { whatsapp, fbShare, tw } from 'vanilla-sharing';

/**
 * Slider component
 */
export default class Share extends Base {
  /**
   * Component Configuration
   */
  get config() {
    return {
      name: 'Slider',
    };
  }

  fbAppId = '400284411016197';

  url = 'https://www.be-a-man.fr/';

  title = 'Ensemble, mobilisons-nous contre les violences faites au femmes !';

  hashtag = 'BeAMan';

  /**
   * Mounted
   */
  mounted() {
    this.$refs.whatsapp.addEventListener('click', this.shareOnWhatsapp);
    this.$refs.facebook.addEventListener('click', this.shareOnFacebook);
    this.$refs.twitter.addEventListener('click', this.shareOnTwitter);
  }

  /**
   * Open the Whatsapp popup share dialog
   */
  shareOnWhatsapp() {
    whatsapp({
      url: this.url,
      title: this.title,
    });
  }

  /**
   * Open the Facebook popup share dialog
   */
  shareOnFacebook() {
    fbShare({
      url: this.url,
      hashtag: this.hashtag,
      fbAppId: this.fbAppId,
    });
  }

  /**
   * Open the Twitter popup share dialog
   */
  shareOnTwitter() {
    tw({
      url: this.url,
      title: this.title,
      hashtags: [this.hashtag],
    });
  }
}


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
