import Base from '@studiometa/js-toolkit';
import withIntersectionObserver from '@studiometa/js-toolkit/decorators/withIntersectionObserver';
import transition, { setClassesOrStyles } from '@studiometa/js-toolkit/utils/css/transition';

/**
 * Lazyload class.
 */
export default class Lazyload extends withIntersectionObserver(Base) {
  /**
   * Class config.
   */
  get config() {
    return {
      name: 'Lazyload',
      log: true,
      styles: {
        unloaded: 'opacity-0',
        active: 'transition duration-500 ease-out',
        loaded: '',
      },
    };
  }

  /**
   * Mounted hook.
   */
  mounted() {
    setClassesOrStyles(this.$el, this.$options.styles.unloaded);
  }

  /**
   * Intersected hook.
   * @param {IntersectionObserverEntry} entry An IntersectionObserverEntry object.
   */
  intersected([entry]) {
    if (entry.isIntersecting) {
      if (typeof window.requestIdleCallback === 'function') {
        window.requestIdleCallback(this.load);
      } else {
        this.load();
      }
    }
  }

  /**
   * Load the image.
   */
  load() {
    const { unloaded, active, loaded } = this.$options.styles;
    const src = this.$el.getAttribute('data-src');
    const srcset = this.$el.getAttribute('data-srcset');

    if (!src || this.isLoaded || this.isLoading) {
      return;
    }

    this.isLoading = true;
    const img = new Image();
    img.onload = () => {
      this.$el.setAttribute('src', src);
      this.$el.removeAttribute('data-src');

      if (srcset) {
        this.$el.setAttribute('srcset', srcset);
        this.$el.removeAttribute('data-srcset');
      }

      this.isLoading = false;
      this.isLoaded = true;
      transition(
        this.$el,
        {
          from: unloaded,
          active,
          to: loaded,
        },
        'keep'
      );
      this.$destroy();
    };
    img.src = src;
  }
}


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
