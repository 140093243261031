import Base from '@studiometa/js-toolkit';

/**
 * Slider component
 */
export default class Slider extends Base {
  /**
   * Component Configuration
   */
  get config() {
    return {
      name: 'Slider',
    };
  }

  /**
   * Mounted
   */
  mounted() {
    this.index = 0;
  }

  /**
   * Previous Slide
   */
  onPrevClick() {
    this.goTo(this.index - 1);
  }

  /**
   * Next Slide
   */
  onNextClick() {
    this.goTo(this.index + 1);
  }

  /**
   * Go to a given slide index
   * @param {int} index given.
   */
  goTo(index) {
    this.index = index;

    if (index > this.$refs.slides.length - 1) {
      this.index = 0;
    }

    if (index < 0) {
      this.index = this.$refs.slides.length - 1;
    }

    this.$refs.slides.forEach((slide) => {
      slide.classList.remove('is-active');
    });

    this.$refs.slides[this.index].classList.add('is-active');
  }
}


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
