import Base from '@studiometa/js-toolkit';
import transition from '@studiometa/js-toolkit/utils/css/transition';

const outQuad = 'cubic-bezier(0.25, 0.46, 0.45, 0.94)';

let isScriptLoaded = false;
let scriptPromise = null;

/**
 * Load Youtube Iframe API Sript
 */
function useYoutubeIframeAPi() {
  if (isScriptLoaded) {
    return Promise.resolve(window.YT);
  }

  if (scriptPromise) {
    return scriptPromise;
  }

  scriptPromise = new Promise((resolve) => {
    window.onYouTubeIframeAPIReady = () => {
      isScriptLoaded = true;
      resolve(window.YT);
      delete window.onYouTubeIframeAPIReady;
      // Garbage collector
      scriptPromise = null;
    };
    const script = document.createElement('script');
    script.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
  });

  return scriptPromise;
}

/**
 * Video component
 */
export default class YoutubePlayer extends Base {
  /**
   * Component Configuration
   */
  get config() {
    return {
      name: 'YoutubePlayer',
      debug: false,
      isReady: false,
    };
  }

  /**
   * Mounted hook
   */
  async mounted() {
    this.animate();
  }

  /**
   *
   */
  animate() {
    // overlay
    transition(
      this.$refs.overlay,
      {
        from: { opacity: 0 },
        active: { transition: `opacity 1.8s ${outQuad}` },
        to: { opacity: 1 },
      },
      'keep'
    );

    // btn play
    transition(
      this.$refs.play,
      {
        from: { opacity: 0 },
        active: { transition: `opacity 1.8s ${outQuad} 1.75s` },
        to: { opacity: 1 },
      },
      'keep'
    );

    // Title
    this.$refs.title.forEach((title, i) => {
      transition(
        title,
        {
          from: { opacity: 0, transform: 'translateY(100%)' },
          active: {
            transition: `transform ${0.6}s ${outQuad} ${0.7 + i / 3}s`,
          },
          to: { opacity: 1, transform: 'translateY(0)' },
        },
        'keep'
      );
    });
  }

  /**
   * Youtube PLayer ready event
   */
  onPlayerReady() {
    this.isReady = true;
    this.player.playVideo();
    this.$el.classList.add('is-playing');
  }

  /**
   * Youtube Player Overlay click handler
   */
  async onOverlayClick() {
    if (!this.isReady) {
      const Youtube = await useYoutubeIframeAPi();
      this.Youtube = Youtube;
      this.player = new Youtube.Player(this.$refs.player, {
        videoId: this.$options.videoId,
        events: {
          onReady: this.onPlayerReady,
          onStateChange: this.onPlayerStateChange,
        },
      });
      return;
    }

    this.player.playVideo();
    this.$el.classList.add('is-playing');
  }

  /**
   * When player change state
   *
   * BUFFERING: 3
   * UNSTARTED: -1
   * CUED: 5
   * ENDED: 0
   * PAUSED: 2
   * PLAYING: 1
   *
   * @param {object} evetn the native Event
   */
  onPlayerStateChange(event) {
    if (
      event.data === this.Youtube.PlayerState.PAUSED ||
      event.data === this.Youtube.PlayerState.ENDED
    ) {
      this.$el.classList.remove('is-playing');
    }
  }
}


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
